import Environment from '@env/environment.model';

export default new Environment(
    {
        baseUrl: 'https://api.test.noty.fr',
        client: {
            id: '1_14ovgnt936680sg4cwgsogc4kggs0k4880kg8sgw4o0ow8o0k4',
            secret: '5wdti8votrwgo4wkgs4c48840k4wsss088scsw4w8kow88wc8s',
        },
        mediaUrl: 'https://media.test.noty.fr',
        pathUrl: '',
    },
    {app: {id: '326134718200387'}},
    {appId: 'bbb0ae78bded37fd7092ba48c664fa52', url: 'https://api.openweathermap.org'},
    {baseUrl: 'http://my.test.noty.fr'},
    {
        partnerToken: '2AFFEC90-AF43-1705-C697-125C9DADC3DF',
        prefix: 'test',
        url: 'https://software.previsite.com/partner.php',
    },
    {dsn: 'https://847d650596f548a4b401989837c21d07@sentry.io/62938', enabled: true, environment: 'test'}
);
